import { Options } from "vue-class-component";
import china from "@/assets/geo/100000.json";

import * as echarts from "echarts/core";
import { CustomSeriesRenderItemReturn } from "echarts/types/dist/shared";
import { TooltipComponent, TooltipComponentOption, GeoComponent, GeoComponentOption } from "echarts/components";
import { MapChart, MapSeriesOption } from "echarts/charts";
import { LinesChart, LinesSeriesOption } from "echarts/charts";
import { ScatterChart, ScatterSeriesOption } from "echarts/charts";
import { EffectScatterChart, EffectScatterSeriesOption } from "echarts/charts";
import { CustomChart, CustomSeriesOption } from "echarts/charts";
import { SVGRenderer } from "echarts/renderers";
import Mixins from "../mixins/mixins";

echarts.use([TooltipComponent, GeoComponent, MapChart, LinesChart, ScatterChart, EffectScatterChart, CustomChart, SVGRenderer]);

type EChartsOption = echarts.ComposeOption<
    TooltipComponentOption | GeoComponentOption | MapSeriesOption | LinesSeriesOption | ScatterSeriesOption | EffectScatterSeriesOption | CustomSeriesOption
>;

@Options({
    name: "app-monitor-dashboard-running-state-map",
    emits: ["click:drilldown"],
})
export default class Map extends Mixins {
    //map名称
    private adname = "速泰智能";
    //地区代码
    private adcode = 100000;
    //地图数据
    private adjson: any = {};
    //预加载map名称
    private adnamePre = "速泰智能";
    //预加载地区代码
    private adcodePre = 100000;
    //预加载地图数据
    private adjsonPre: any = {};

    public getGeoData() {
        return {
            adname: this.adname,
            adcode: this.adcode,
            adjson: this.adjson,
            adnamePre: this.adnamePre,
            adcodePre: this.adcodePre,
            adjsonPre: this.adjsonPre,
        };
    }

    public setGeoData(adname: string, adcode: number, adjson: any): any {
        this.adname = adname;
        this.adcode = adcode;
        this.adjson = adjson;
    }

    public enterPreGeoData(): any {
        this.adname = this.adnamePre;
        this.adcode = this.adcodePre;
        this.adjson = this.adjsonPre;
    }

    public preGeoData(adname: string, adcode: number, adjson: any): any {
        this.adnamePre = adname;
        this.adcodePre = adcode;
        this.adjsonPre = adjson;
    }

    public mounted() {
        this.option = this.mapOption;
        this.preGeoData("速泰智能", 100000, china);
        this.enterPreGeoData();
        echarts.registerMap(this.adname, this.adjson);
        echarts.registerMap(this.adname + "1", this.adjson);
        //canvas, svg
        this.init(echarts, this.$refs.chartDom as HTMLElement, "svg").then(() => {
            this.chart.on("click", (res: any) => {
                if (!res.name) res.name = this.centerToName(res.data);
                if (res.name) this.$emit("click:drilldown", res);
            });
        });
    }

    public reloadMap(adname: string, adcode: number, callBack: Function) {
        if (this.$tools.isEmpty(adcode)) return;
        fetch(`/geo/${adcode}.json`)
            .then((response) => {
                if (!response.ok) {
                    this.toast.error("地图数据获取失败");
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                this.preGeoData(adname, adcode, data);
                echarts.registerMap(this.adnamePre, this.adjsonPre);
                echarts.registerMap(this.adnamePre + "1", this.adjsonPre);
                callBack();
            })
            .catch((error) => {
                this.toast.error("地图数据获取错误");
                console.error("Error:", error);
            });
    }

    public refreshData() {
        this.enterPreGeoData();
        const nowOption = {
            geo: [
                {
                    map: this.adname,
                },
                {
                    map: this.adname + "1",
                },
                {
                    map: this.adname + "1",
                },
            ],
            series: [
                {
                    data: this.customData,
                },
                {
                    data: this.scatterData,
                },
            ],
        };
        //console.log(this.customData);
        this.option.geo[0].map = this.adname;
        this.option.geo[1].map = this.adname + "1";
        this.option.geo[2].map = this.adname + "1";
        this.option.series[0].data = this.customData;
        this.option.series[1].data = this.scatterData;
        this.chart.setOption(nowOption);
    }
    public centerToName(center: any) {
        const item: any = this.adjson.features.find((features: any) => features.properties.center[0] === center[0] && features.properties.center[1] === center[1]);
        if (!item) {
            this.toast.error("地图匹配失败");
            return null;
        }
        return item.properties.name;
    }

    get customData() {
        return this.eChartData.statusMapData
            .map((item: any) => {
                const adcodeArray = this.eChartData.currentTableData.filter((obj: any) => obj.adcode === item.adcode);
                return [item.center[0], item.center[1], adcodeArray.length];
            })
            .filter((obj: any) => obj[2] > 0);
    }
    get scatterData() {
        return this.customData.map((item: any) => {
            return [item[0], item[1]];
        });
    }
    public tooltipFormatter(item: any) {
        return `${this.centerToName(item.data)}<br>异常数: ${this.customData[item.dataIndex][2]}`;
    }
    public scatterFormatter(item: any) {
        return `${this.centerToName(item.data)}: ${this.customData[item.dataIndex][2]}`;
    }

    get mapOption(): EChartsOption {
        return {
            tooltip: {
                trigger: "item",
                show: true,
                enterable: true,
                textStyle: {
                    fontSize: 14,
                    color: "#fff",
                },
                backgroundColor: "rgba(0,0,0,0.5)",
                formatter: this.tooltipFormatter,
            },
            // 地图图层样式
            geo: [
                {
                    map: this.adname,
                    aspectScale: 0.9,
                    roam: false, //是否允许缩放
                    // roam:true,
                    //zoom: 1.1, //默认显示级别
                    layoutSize: "140%",
                    layoutCenter: ["50%", "50%"],
                    itemStyle: {
                        areaColor: "transparent",
                        borderColor: "#37C1FD",
                        borderWidth: 2,
                    },
                    emphasis: {
                        itemStyle: {
                            areaColor: "#72b2ff",
                        },
                        label: {
                            show: false,
                            color: "#fff",
                        },
                    },
                    zlevel: 3,
                    // regions: [
                    //     // 区域配置
                    //     {
                    //         name: "湖北", //区块名称
                    //         itemStyle: {
                    //             areaColor: "rgb(247, 192, 35)", // 区域颜色
                    //         },
                    //     },
                    // ],
                },
                {
                    map: this.adname + "1",
                    aspectScale: 0.9,
                    roam: false, //是否允许缩放
                    //zoom: 1.1, //默认显示级别
                    layoutSize: "140%",
                    layoutCenter: ["50%", "50%"],
                    itemStyle: {
                        areaColor: {
                            type: "radial",
                            x: 0.7,
                            y: 0.5,
                            r: 0.4,
                            colorStops: [
                                {
                                    offset: 0,
                                    //color: "#72b2ff", // 0% 处的颜色
                                    // color:"rgba(8, 79, 207)"
                                    color: "rgba(1, 32, 137,0.5)",
                                },
                                {
                                    offset: 1,
                                    color: "rgba(1, 32, 137,0.5)", // 100% 处的颜色
                                },
                            ],
                            global: false, // 缺省为 false
                        },
                        borderColor: "#37C1FD",
                        borderWidth: 2,
                    },
                    zlevel: 2,
                    silent: true,
                },
                {
                    map: this.adname + "1",
                    aspectScale: 0.9,
                    roam: false, //是否允许缩放
                    //zoom: 1.2, //默认显示级别
                    layoutSize: "140%",
                    layoutCenter: ["50%", "52%"],
                    itemStyle: {
                        areaColor: "#005DDC",
                        borderColor: "#329BF5",
                        borderWidth: 1,
                    },
                    zlevel: 1,
                    silent: true,
                },
            ],
            series: [
                {
                    type: "custom",
                    coordinateSystem: "geo",
                    geoIndex: 0,
                    zlevel: 5,
                    data: this.customData,
                    renderItem(params, api) {
                        const coordinate = api.coord([api.value(0, params.dataIndex), api.value(1, params.dataIndex)]);

                        const circles: any = [];
                        for (let i = 0; i < 5; i++) {
                            circles.push({
                                type: "circle",
                                shape: {
                                    cx: 0,
                                    cy: 0,
                                    r: 30,
                                },
                                style: {
                                    stroke: "red", //red,yellow
                                    fill: "none",
                                    lineWidth: 2,
                                },
                                // Ripple animation
                                keyframeAnimation: {
                                    duration: 4000,
                                    loop: true,
                                    delay: (-i / 4) * 4000,
                                    keyframes: [
                                        {
                                            percent: 0,
                                            scaleX: 0,
                                            scaleY: 0,
                                            style: {
                                                opacity: 1,
                                            },
                                        },
                                        {
                                            percent: 1,
                                            scaleX: 1,
                                            scaleY: 0.4,
                                            style: {
                                                opacity: 0,
                                            },
                                        },
                                    ],
                                },
                            });
                        }
                        return {
                            type: "group",
                            x: coordinate[0],
                            y: coordinate[1],
                            children: [
                                ...circles,
                                {
                                    type: "path",
                                    shape: {
                                        d: "M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z",
                                        x: -10,
                                        y: -35,
                                        width: "20",
                                        height: "40",
                                    },
                                    style: {
                                        fill: "red",
                                    },
                                    // Jump animation.
                                    keyframeAnimation: {
                                        duration: 1000,
                                        loop: true,
                                        delay: Math.random() * 1000,
                                        keyframes: [
                                            {
                                                y: -10,
                                                percent: 0.5,
                                                easing: "cubicOut",
                                            },
                                            {
                                                y: 0,
                                                percent: 1,
                                                easing: "bounceOut",
                                            },
                                        ],
                                    },
                                },
                            ],
                        } as CustomSeriesRenderItemReturn;
                    },
                },
                // 圆点设置
                {
                    type: "scatter",
                    coordinateSystem: "geo",
                    geoIndex: 0,
                    zlevel: 4,
                    symbol: "circle",
                    symbolSize: [10, 5],
                    silent: true,
                    data: this.scatterData,
                    label: {
                        show: true,
                        position: "right",
                        formatter: this.scatterFormatter,
                        padding: [4, 8],
                        // backgroundColor: "#003F5E",
                        backgroundColor: "rgb(0,63,94, .8)",
                        borderRadius: 5,
                        // borderColor: "#67F0EF",
                        // borderWidth: 1,
                        color: "#67F0EF",
                    },
                    itemStyle: {
                        color: "rgb(255,0,0, .4)",
                        opacity: 1,
                        shadowColor: "#000",
                        shadowBlur: 5,
                        shadowOffsetY: 2,
                    },
                },
            ],
        };
    }
}
